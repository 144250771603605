<template>
    <div>
     <el-dialog
      title="查看详情"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      @open="opDialog"
    >
      
      <el-form ref="addform" :model="form" label-width="120px" size="mini">
        <div>
         <div><span class="icon_line"></span><span class="title">工程信息</span></div>
         <el-row>
             <el-col :span="12">
          <el-form-item label="工程编号:" prop="positionName">
           {{form.projectNumber}}
          </el-form-item>
             </el-col>
             <el-col :span="12">
          <el-form-item label="工程类型:" prop="positionName">
           {{form.projectType}}
          </el-form-item>
          </el-col>
          <el-form-item label="工程名称:" prop="positionName">
           {{form.projectName}}
          </el-form-item>
          <el-form-item label="工程地点:" prop="positionName">
           {{form.projectSite}}
          </el-form-item>
          <el-col :span="12">
          <el-form-item label="现场管理员:" prop="positionName">
           {{form.siteManager}}
          </el-form-item>
          </el-col>
             <el-col :span="12">
          <el-form-item label="第三方施工队:" prop="positionName">
           {{form.constructionTeam}}
          </el-form-item>
          </el-col>
         </el-row>
        </div>
        <div>
           <div><span class="icon_line"></span><span class="title">申请信息</span></div>
           <el-row>
             <el-col :span="12">
          <el-form-item label="提交人:" prop="positionCode">
            {{form.createBy}}
          </el-form-item>
          </el-col>
             <el-col :span="12">
          <el-form-item label="提交时间:" prop="positionCode">
            {{form.createTime}}
          </el-form-item>
         </el-col>
         </el-row>
        </div>
        <div v-if="form.checkType">
          <div><span class="icon_line"></span><span class="title">审核信息</span></div>
           <el-row>
             <el-col :span="23">
          <el-form-item label="审核结果:">
            <span style="word-break:break-all" v-if="form.checkType==1">通过</span>
            <span style="word-break:break-all;color:#EC2B2C"  v-else>驳回</span>
          </el-form-item>
          </el-col>
             <el-col :span="12">
          <el-form-item label="审核人:">
            <span style="word-break:break-all">{{form.checkBy}}</span>
          </el-form-item>
          </el-col>
             <el-col :span="12">
          <el-form-item label="审核时间:">
            <span style="word-break:break-all">{{form.checkTime}}</span>
          </el-form-item>
          </el-col>
             <el-col :span="24" v-if="form.checkProposal">
          <el-form-item label="驳回原因:">
            <span style="word-break:break-all">{{form.checkProposal}}</span>
          </el-form-item>
          </el-col>
          </el-row>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {bzApply_getOperationDetail,bzApply_getApplyInfo} from '../../RequestPort/report/reportApply'
export default {
  components: { },
  name: 'applyList',
  mixins: [],
  data() {
    return {
      dialogVisible:false,
      form:{}
    }
  },
  props: {
    objData: {
      type: Object,
    }
  },
  mounted() {
      
  },
  methods: {
      opDialog(){
        this.form = this.objData;
        
      },
      clearnF(){
        this.objData={}
    },
      
      setdialogVisible(flag){
          this.dialogVisible = flag;
      }
  }
}
</script>
<style scoped>
.icon_line{
  width: 3px;
height: 16px;
background: #1082FF;
display: inline-block;
}
.title{
  font-weight: 400;font-size: 16px; margin-left: 5px;height: 16px; line-height: 16px;
}
</style>
