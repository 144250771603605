import axios from '@/common/js/request'

export function gasHomework_checkPendingList(data){
    const gasHomework_checkPendingList = axios({
        url: '/gasHomework/checkPendingList',
        method: 'get',
        params:data
    })
    return gasHomework_checkPendingList
}
export function gasHomework_checkPendingType(data){
    const gasHomework_checkPendingType = axios({
        url: '/gasHomework/checkPendingType',
        method: 'post',
        data:data
    })
    return gasHomework_checkPendingType
}
export function gasHomework_approvedMemo(data){
    const gasHomework_approvedMemo = axios({
        url: '/gasHomework/approvedMemo',
        method: 'get',
        params:data
    })
    return gasHomework_approvedMemo
}
export function gasHomework_planUploadedList(data){
    const gasHomework_planUploadedList = axios({
        url: '/gasHomework/planUploadedList',
        method: 'get',
        params:data
    })
    return gasHomework_planUploadedList
}
export function gasHomework_planUploadedRecord(data){
    const gasHomework_planUploadedRecord = axios({
        url: '/gasHomework/planUploadedRecord',
        method: 'get',
        params:data
    })
    return gasHomework_planUploadedRecord
}gasHomework_leaderRecordsList
export function gasHomework_ministerApprovalPending(data){
    const gasHomework_ministerApprovalPending = axios({
        url: '/gasHomework/ministerApprovalPending',
        method: 'get',
        params:data
    })
    return gasHomework_ministerApprovalPending
}
export function gasHomework_leaderRecordsList(data){
    const gasHomework_leaderRecordsList = axios({
        url: '/gasHomework/leaderRecordsList',
        method: 'get',
        params:data
    })
    return gasHomework_leaderRecordsList
}
export function gasHomework_superiorLeadersApproval(data){
    const gasHomework_superiorLeadersApproval = axios({
        url: '/gasHomework/superiorLeadersApproval',
        method: 'post',
        data:data
    })
    return gasHomework_superiorLeadersApproval
}
